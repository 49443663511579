/*.App {*/
/*  text-align: center;*/
/*}*/

.App-logo {
  /*height: 40vmin;*/
  /*pointer-events: none;*/
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

.main-container{
  margin: 24px 16px 0;

  /*min-height: c;*/

}

.site-layout-background{
  background-color: #f0f2f5;
  padding: 24px;
  min-height: calc(100vh - 175px);
  height:auto;
  /*height: auto;*/
}

.component-box{
  border-top: 2px #697789 solid;
  padding: 20px;
/*//elevation: 1px;*/
  background-color: white;
  box-shadow: 0px 1px #697789;
  margin-top: 10px;
  min-height: 150px;
  height:auto
}

.spinner{
  text-align: center;
}
.react-csv-input{
  margin: 10px 0;
}


.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  padding: 5px 5px;
}

.ant-table-tbody > tr > td{
  padding: 5px;

}

.ant-table-cell{
  padding: 5px;
}

