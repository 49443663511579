body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.estimate-panel{
  //background-color: rgba(50, 52, 65, 0.13);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 20px;
  h3{
    font-size: 18px;
    font-weight: bold;
  }
  span{
    font-weight: normal;
    font-size: 17px;
  }
}
.chart-one{
  margin: 10px;
}
.row-x{
  height: 20px;
  padding: 0!important;
}
.img-cont{
  max-width: 55%;
  margin-top: 20px;
  img{
    max-width: 100%;
    width: auto;
    max-height: 450px;
    margin: 10px;
  }
}
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}