.container {
  background-color: #222736;
  padding: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
}

.login-box {
  width: 350px;
  background-color: white;

}

.sign-in-box{
  padding: 30px;
  background-color: #3B4B71;
}

.sign-in-box h2,.sign-in-box span  {
  color: white;

}

.login-button {
  width: 100%;
}

.login-form-content{
  background-color: #2A3042;
  padding: 30px;
}
.sign-in-round{
  position: absolute;
  border: 10px;
  border-radius: 50%;
  border: 10px solid #2A3042;
  height: 50px;
  width: 50px;
  background-color: #697789;
}

.login-form label{
  color: white;
}

.login-form input[type='password'] ::before, .login-form input[type='password']{
  color: white;
  // background-color: #323441;
  border: none;
}
.login-form-password{
  color: white;
  background-color: #323441;
  border: none;
}


.login-form input{
  color: white;
  background-color: #323441;
  border: none;
}

.login-form .ant-form-item-has-error .ant-input:not(.ant-form-item-has-error .ant-input-disabled),
.login-form .ant-form-item-has-error .ant-input-affix-wrapper:not(.ant-form-item-has-error .ant-input-affix-wrapper-disabled) {
  background-color: #323441;
}